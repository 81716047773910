export const contact_type_dict = {
  partner: 'ビジネスパートナーに関して',
  comachicart: 'comachicartに関して',
  recruit: '求人に関して',
  other: 'その他',
};

export const contact_type_email_map = {
  [contact_type_dict.partner]: process.env.GATSBY_EIGYO_EMAIL,
  [contact_type_dict.comachicart]: process.env.GATSBY_COMACHI_EMAIL,
  [contact_type_dict.recruit]: process.env.GATSBY_RECRUIT_EMAIL,
  [contact_type_dict.other]: process.env.GATSBY_INFO_EMAIL,
};

export const domain = process.env.DOMAIN;

export const releace_endpoint_add_standard_plan =
    process.env.RELEACE_ENDPOINT_ADD_STANDARD_PLAN;

export const releace_endpoint_20240723 = process.env.RELEACE_ENDPOINT_20240723;

export const releace_endpoint_20240823 = process.env.RELEACE_ENDPOINT_20240823;

export const releace_endpoint_20240919 = process.env.RELEACE_ENDPOINT_20240919;

export const news_endpoint = process.env.NEWS_ENDPOINT;

export const releace_endpoint_2auth_manual =
    process.env.RELEACE_ENDPOINT_2AUTH_MANUAL;

export const cese_study_list = process.env.CASE_STUDY_LIST;

export const comachi_application_url = process.env.COMACHI_APPLICATION_URL;
